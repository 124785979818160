@import '../../variables.scss';

.tableHeader {
  border: 1px solid $secondary-bg;
  background-color: $secondary-bg;

  & > div {
    font-family: Lora;
    font-weight: bold;
    padding: 12px 6px;
    display: flex;
    margin: auto 0;
    word-break: keep-all;
    align-items: center;

    &:not(:first-child) {
      margin: auto;
      justify-content: center;
      text-align: center;
    }

    & > div {
      display: grid;
      height: fit-content;
      padding: 0;
      padding-top: 2px;

      img {
        margin: 2px;
      }

      img:first-child {
        transform: rotate(180deg);
      }
    }
  }
}
