.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 150px 0 100px 0;
  overflow: auto;
  z-index: 99999;
}

.popup {
  padding: 40px;
  padding-bottom: 60px;
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 8px;
  border-radius: 10px;
  width: 616px;
  position: relative;

  & > div:nth-last-child(2) {
    margin-bottom: 1.5rem;
  }

  & > img {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
  }
}

.footerButtons {
  display: flex;
  justify-content: space-between;
}
