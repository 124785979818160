@import '../../variables.scss';

.layout {
  padding: 50px;
  padding-top: 40px;
  background-color: $primary-bg;

  @media screen and (min-width: 1300px) {
    padding: 85px;
    padding-top: 40px;
  }

  @media screen and (min-width: 1600px) {
    padding: 115px;
    padding-top: 40px;
  }
}

.wide {
  @media screen and (min-width: 1300px) {
    padding: 70px;
  }

  @media screen and (min-width: 1600px) {
    padding: 100px;
  }
}
