@import '../../variables.scss';

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.option {
  display: flex;
  align-items: center;
  position: relative;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: white;
    border: 1px solid $text-primary;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: white;
    }
  }

  &__input:checked ~ .option__checkmark {
    background-color: $dark-bg;
  }

  &__input:checked ~ .option__checkmark:after {
    display: block;
  }

  &__label {
    margin-left: 25px;
    margin-bottom: 0;
  }
}
