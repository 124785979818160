@import 'src/variables.scss';

.title {
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
  color: $dark-bg;
  font-family: Dosis;

  &:after {
    background-color: $green;
    display: block;
    height: 1px;
    content: '';
    margin-top: 6px;
  }
}
