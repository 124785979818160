@import '../../variables.scss';

footer {
  padding: 40px 0;
  background-color: $dark-bg;
  text-align: center;

  div,
  span {
    color: #ffffff;
  }

  & > div:first-child {
    margin-bottom: 1rem;
    display: flex;
    gap: 18px;
    justify-content: center;
  }
}
