@import '../../variables.scss';

.flex {
  display: flex;
  gap: 1.75rem;
  justify-content: space-between;
}

.leftPanel {
  flex: 1 0 40%;
}

.rightPanel {
  flex: 0 1 60%;
}

.activity {
  @extend .rightPanel;

  overflow-y: auto;

  & > div {
    height: 0;
    min-height: 100%;
    padding: 0 0.25rem;
  }
}

.tabNames {
  display: flex;
  color: inherit;

  & > div {
    color: inherit;
    display: inline;
    margin-right: 1rem;
    margin-bottom: -6px;
    cursor: pointer;
  }
}

.activeTab {
  &:after {
    background-color: $green;
    display: block;
    height: 2px;
    content: '';
    margin-top: 4px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.messageButtons {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.mezzaActions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}
