@import '../../variables.scss';

.wrapper {
  text-align: left;
  font-weight: bold;
  font-size: 1rem;

  & > div > span {
    margin-left: 0.5em;
  }

  h3 {
    background-color: $green;
    color: #ffffff;
    padding: 0.5rem;

    > span {
      margin-left: 0.5em;
      color: inherit;
    }
  }
}
