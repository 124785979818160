@import '../../variables.scss';

.primary {
  background-color: $green;
  color: #ffffff;
  border: 1px solid transparent;
}

.primaryInverse {
  color: $green;
  background-color: #ffffff;
  border: 1px solid $green;
}

.secondary {
  background-color: $dark-bg;
  color: #ffffff;
  border: 1px solid $dark-bg;
}

.secondaryInverse {
  color: $dark-bg;
  background-color: #ffffff;
  border: 1px solid $dark-bg;
}
