.wrapper {
  display: flex;
  justify-content: flex-start;

  input {
    display: inline;
    width: auto;
    margin-right: 0.5rem;
  }
}
