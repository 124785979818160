@import '../../variables.scss';

.wrapper {
  padding: 1rem 1.25rem 0.75rem 1.25rem;
  box-shadow: #00000040 0 3px 6px;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.stateWrapper {
  @extend .wrapper;

  background-color: $secondary-bg;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
}

.activity {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.25rem;

  img {
    margin-right: 0.5rem;
  }
}

.body {
  display: grid;
  grid-gap: 1rem;
}
