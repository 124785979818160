@import '../../variables.scss';

.wrapper {
  display: block;
  width: 100%;
  max-width: 560px;
  border: 2px solid #e3e2e2;
  border-radius: 3px;
  margin-bottom: 0.75rem;
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  color: inherit;

  & > div:first-child {
    padding: 9px 6px;
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 5px;
    color: inherit;
    overflow: hidden;

    img {
      height: fit-content;
    }
  }
}

.optionsWrapper {
  border-radius: 5px;
  position: absolute;
  top: 43px;
  background: #ffffff;
  margin: 0;
  left: -2px;
  right: -2px;
  border: 2px solid #e3e2e2;
  z-index: 1;
  max-height: 180px;
  overflow: auto;
}

.options {
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
    padding: 5px;
    white-space: break-spaces;

    &:hover {
      background-color: $dark-bg;
      color: #ffffff;
    }
  }
}

.targeted {
  background-color: $dark-bg;
  color: #ffffff;
}

.disabled {
  opacity: 0.5;
}
