@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: normal;
  src: url('./Dosis-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: bold;
  src: url('./Lora-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  src: url('./Raleway-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold;
  src: url('./Raleway-SemiBold.ttf') format('truetype');
}
