@import '../../variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 50px;
  background-color: #ffffff;
  font-size: 1.25rem;
  text-transform: uppercase;

  @media screen and (min-width: 1300px) {
    padding: 1rem 85px;
  }

  @media screen and (min-width: 1600px) {
    padding: 1rem 115px;
  }
}

.user {
  position: relative;
  display: flex;
  align-items: center;
  color: #18181a;
  cursor: pointer;

  img {
    margin-left: 10px;
  }
}

.activeMenu {
  position: absolute;
  top: -0.75rem;
  right: 0;
  background-color: $secondary-bg;
  border-radius: 10px;
  z-index: 1;
  width: max-content;

  div {
    padding: 0.75rem 1rem;
    cursor: pointer;
    color: #18181a;
    position: relative;

    & > a {
      padding: 0.75rem 1rem;
      display: block;
    }

    &:hover {
      background-color: $dark-bg;
      color: #ffffff;
    }

    &:nth-child(1):after,
    &:nth-child(2):after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      left: 1rem;
      right: 1rem;
      background-color: #d5dce6;
      bottom: 0;
    }
  }
}

.rightMenu {
  display: flex;
  gap: 40px;
  align-items: center;
}

.logo {
  width: 217px;
  height: 56px;
}
