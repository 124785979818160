@import 'variables.scss';

* {
  box-sizing: border-box;
}

body {
  color: $text-primary;
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  font-size: 16px;
  line-height: 1.2;
}

#root {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2 {
  margin-top: 0;
  text-transform: uppercase;
}

h1 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 0.75rem;

  &:after {
    background-color: $green;
    display: block;
    width: 248px;
    height: 1px;
    content: '';
    margin-top: 12px;
  }
}

h2 {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1.75rem;
}

h3 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: inherit;
}

::placeholder {
  color: #bec4cc;
  font-family: 'Raleway';
}

input,
textarea {
  display: block;
  width: 100%;
  max-width: 560px;
  padding: 9px 12px;
  border: 2px solid #e3e2e2;
  border-radius: 3px;
  margin-bottom: 0.75rem;
  background-color: #fff;
  font-size: 1rem;
  color: inherit;
  font-family: 'Raleway';

  &:focus-visible {
    outline: none;
  }
}

button:not([type='button']) {
  font-size: 1rem;
  padding: 10px 12px;
  font-weight: bold;
  //min-width: 124px;
  text-align: center;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;
  min-width: max-content;
}

a[type='link'] {
  font-family: 'Lora';
  color: $green;
  text-decoration-line: underline;
}

a {
  color: inherit;
  text-decoration: none;
}

.mb-25 {
  margin-bottom: 25px;
}

.spacer {
  margin-bottom: 0.5em;
}

.input-group {
  display: flex;
  gap: 2rem;
  align-items: baseline;
}

// fullcalendar overrides

:root {
  --fc-button-bg-color: transparent;
  --fc-button-active-bg-color: transparent;
  --fc-button-hover-bg-color: transparent;
  --fc-button-border-color: transparent;
  --fc-today-bg-color: #ebf3fc;
  --fc-button-text-color: #293139;
}

.fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.fc .fc-toolbar-title {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 1.25rem;
}

.fc-theme-standard th,
.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc table {
  border-collapse: separate;
}

.fc table td[role='presentation'] {
  border: none;
}

.fc-col-header-cell {
  text-transform: capitalize;
  font-weight: normal;
}

.fc-event {
  padding: 0.3rem;
  font-size: 0.75rem;
  font-weight: bold;
  cursor: pointer;
}

.fc-daygrid-day-events {
  max-height: 60px !important;
  height: 60px !important;

  // min 4 events + 1 for invisible "bottom" element
  &:has(> :nth-child(5)) {
    display: flex;
    flex-wrap: wrap;

    .fc-daygrid-event-harness {
      width: 50%;
    }
  }
}

.fc-daygrid-day-bottom {
  position: absolute;
  bottom: calc(100% + 4px);
}

.fc-daygrid-day-frame {
  max-height: 100px;
}
